/* eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import { paths } from '@/constants/paths'
import { useToast } from '@/molecules/Toast'
import { getWebClient } from '@/services/ApolloClient'
import { useGuildUser, useUserGuildTickets } from '@/services/GuildUserService'
import { TheatricalPromoType, TicketPromoTypes } from '@/services/Theatrical/contentfulQueries'
import { getAvailableUserDiscounts, getTicketSalesProps } from '@/services/Theatrical/queries'
import { isTheatricalPromoValid } from '@/services/Theatrical/utils'
import { useUser } from '@/services/UserService'
import { TheatricalPromoExperimentVariation } from '@/types/codegen-contentful'
import {
  ReservationObject,
  ReservationTicket,
  SweepstakesScheduleObject,
  TheatricalDiscount,
  TheatricalReleaseObject,
  TheatricalShowtime,
  TheatricalShowtimeVenue,
} from '@/types/codegen-federation'
import { ReactFCC } from '@/types/react'
import { useLocale } from '@/utils/LocaleUtil'
import { useSafeAnalytics } from '@/utils/analytics'
import { getBooleanFromLocalStorage, writeToLocalStorage } from '@/utils/local-storage'
import { cLogger } from '@/utils/logging/client-logger'
import { useTranslate } from '@/utils/translate/translate-client'
import { LocaleWarningModalState } from '@/views/TicketCheckoutViews/ShowtimesView/components/VenueList'
import { SharingModal } from '@/views/TicketCheckoutViews/ShowtimesView/components/modals/SharingModal'
import { parseTheatricalDate, publishTicketIntent } from '@/views/TicketCheckoutViews/ShowtimesView/utils/utils'
import { getLanguageName } from '@/views/TicketCheckoutViews/utils'

interface TicketsContextValues {
  theatricalRelease: TheatricalReleaseObject
  query: ParsedUrlQuery
  routerIsReady: boolean
  openSharingModal: () => void
  closeSharingModal: () => void
  guildTicketsCount: number
  guildDiscountCodes: string[]
  isFreeTicketPromo: boolean
  discount: DiscountType | undefined
  trackTicketIntent: (analyticsPayload: TicketsAnalyticsPayload) => void
  detectLanguageDifference: (showTime: TheatricalShowtime) => boolean
  handleMobileShare: () => void
  shouldHideDiscountsForMarketing: boolean
  setPromoExperiment: React.Dispatch<React.SetStateAction<TheatricalPromoExperimentVariation | null>>
  promoExperiment: TheatricalPromoExperimentVariation | null
  promo: TheatricalPromoType | undefined
  handleLanguageWarning: (
    setLocaleWarningModalState: React.Dispatch<React.SetStateAction<LocaleWarningModalState>>,
    showTime: TheatricalShowtime,
    callback: () => void,
  ) => void
  adjustedDiscount: number | undefined | null
  getEstimatedEntries: (quantity: number) => number
  getFinalEntries: (quantity: number, reservation?: ReservationObject) => number
  ticketEntriesTable: TicketEntriesTable | null
}

export type DiscountType = { description: string; value: number; name?: string; code: string; category?: string }

const TicketsContext = React.createContext<TicketsContextValues | null>(null)

export type TicketsAnalyticsPayload = {
  venue: TheatricalShowtimeVenue
  latitude: number | undefined
  longitude: number | undefined
  theatricalName: string
  theatricalSlug: string
  projectSlug: string
}

interface TicketsContextProviderProps {
  theatricalRelease: TheatricalReleaseObject
  promo?: TheatricalPromoType
  reservation?: ReservationObject | undefined
  defaultSearchDate?: Date
}

interface TicketEntriesObject {
  entries: number
  appliedTickets: number
}

interface TicketEntriesTable {
  guild: TicketEntriesObject
  normal: TicketEntriesObject
  presale: TicketEntriesObject
}

export const TicketsContextProvider: ReactFCC<TicketsContextProviderProps> = ({
  children,
  theatricalRelease,
  reservation,
  promo,
}) => {
  const { asPath, query, isReady: routerIsReady } = useRouter()
  const [displaySharingModal, setDisplaySharingModal] = useState(false)
  const [promoExperiment, setPromoExperiment] = useState<TheatricalPromoExperimentVariation | null>(null)
  const { t } = useTranslate('tickets')
  const { showToast } = useToast()
  const { locale } = useLocale()
  const { track } = useSafeAnalytics()
  const isDatesDisabled = false
  const currentPathWithoutQueryParams = asPath.split('?')[0]
  const releaseDate = useMemo(() => {
    if (theatricalRelease?.region?.releaseDate) {
      return parseTheatricalDate(new Date(theatricalRelease?.region?.releaseDate).toISOString())
    }
    if (theatricalRelease?.releaseDate) return parseTheatricalDate(theatricalRelease.releaseDate)
    return null
  }, [theatricalRelease?.region?.releaseDate, theatricalRelease.releaseDate])
  const isFreeTicketPromo = query?.promo === 'sof-claim-free' || query?.promo === 'claim-free-ticket'
  const { user, isLoggedIn } = useUser()

  const closeSharingModal = useCallback(() => setDisplaySharingModal(false), [setDisplaySharingModal])

  const openSharingModal = useCallback(() => setDisplaySharingModal(true), [setDisplaySharingModal])

  const handleMobileShare = useCallback(async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: t('ticketsSEOTitle', `{{theatricalName}} Tickets & Showtimes | Angel Studios`, {
            theatricalName: theatricalRelease?.title,
          }),
          text: t(
            'ticketsSEODescription',
            'Buy {{theatricalName}} tickets, watch trailers and find showtimes near you.',
            {
              theatricalName: theatricalRelease?.title,
            },
          ),
          url: `${paths.base}${currentPathWithoutQueryParams}`,
        })
      } catch (error) {
        cLogger().error('An error occurred sharing ticket page on mobile', error)
      }
    }
  }, [currentPathWithoutQueryParams, t, theatricalRelease?.title])

  const setExperimentPromo = useCallback(() => {
    if (promo?.experimentDataCollection?.items && promo?.experimentDataCollection?.items?.length > 0) {
      const experimentName = promo.experimentDataCollection.items[0] ?? null
      setPromoExperiment(experimentName)
    }
  }, [promo, setPromoExperiment])

  useEffect(() => {
    setExperimentPromo()
  }, [setExperimentPromo])

  const shouldHideDiscountsForMarketing = query?.promoOverride === 'true'

  const { isGuildMember } = useGuildUser()
  const client = getWebClient()
  const { numberAvailable: guildTicketsCount = 0, discountCodes } = useUserGuildTickets({
    theatricalSlug: theatricalRelease.projectSlug,
    client,
    skip: !isGuildMember,
  })

  const guildDiscountCodes = useMemo(() => {
    return discountCodes?.map((theatricalDiscount) => theatricalDiscount?.code)
  }, [discountCodes])

  const [giveawayPromo, setGiveawayPromo] = useState<SweepstakesScheduleObject | null>(null)
  const [availableDiscounts, setAvailableDiscounts] = useState<TheatricalDiscount[] | null>(null)

  useEffect(() => {
    const fetchGiveawayPromo = async () => {
      const sweepsteaksSchedule = await getTicketSalesProps({
        theatricalSlug: theatricalRelease?.projectSlug,
        client,
        date: new Date().toISOString().split('T')[0],
      })
      if (sweepsteaksSchedule && sweepsteaksSchedule.length > 0) {
        setGiveawayPromo(sweepsteaksSchedule[0])
      }
    }

    fetchGiveawayPromo()
  }, [theatricalRelease?.projectSlug, client])

  useEffect(() => {
    if (isLoggedIn) {
      const fetchAvailableDiscounts = async () => {
        const fetchedDiscounts = await getAvailableUserDiscounts({
          email: user?.email as string,
          theatricalSlug: theatricalRelease?.projectSlug,
          client,
        })
        if (fetchedDiscounts) {
          setAvailableDiscounts(fetchedDiscounts)
        }
      }

      fetchAvailableDiscounts()
    }

    setAvailableDiscounts(null)
  }, [theatricalRelease?.projectSlug, client, isLoggedIn, user])

  const [ticketEntriesTable, setTicketEntriesTable] = useState<TicketEntriesTable | null>(null)

  const updateTicketEntriesTable = (updatedTable: TicketEntriesTable) => {
    setTicketEntriesTable((prev) => {
      const hasChanged =
        !prev ||
        prev.guild.entries !== updatedTable.guild.entries ||
        prev.guild.appliedTickets !== updatedTable.guild.appliedTickets ||
        prev.normal.entries !== updatedTable.normal.entries ||
        prev.normal.appliedTickets !== updatedTable.normal.appliedTickets ||
        prev.presale.entries !== updatedTable.presale.entries ||
        prev.presale.appliedTickets !== updatedTable.presale.appliedTickets

      return hasChanged ? updatedTable : prev
    })
  }

  // This calculates estimated entries on the assumption all discounts are used
  const getEstimatedEntries = useCallback(
    (quantity: number) => {
      let remainingQty = quantity

      const updatedTicketEntriesTable: TicketEntriesTable = {
        guild: { entries: 0, appliedTickets: 0 },
        normal: { entries: 0, appliedTickets: 0 },
        presale: { entries: 0, appliedTickets: 0 },
      }

      const findPromoItem = (ticketType: string) => {
        return giveawayPromo?.items?.find((item) => item.ticketType === ticketType) ?? null
      }

      const guildDiscounts = availableDiscounts?.filter((discount) => discount.category === 'GUILD') || []
      const guildPromoItem = findPromoItem('GUILD')
      const isGuildEligible = guildDiscounts.length > 1

      if (guildPromoItem && isGuildEligible) {
        guildDiscounts.forEach((discount) => {
          const uses = Math.min(discount.remainingUses ?? 0, remainingQty)
          const guildEntries = uses * guildPromoItem.entriesPerTicket * guildPromoItem.multiplier

          updatedTicketEntriesTable.guild.entries += guildEntries
          updatedTicketEntriesTable.guild.entries = Math.min(updatedTicketEntriesTable.guild.entries, 100) // Cap at 100
          updatedTicketEntriesTable.guild.appliedTickets += uses

          remainingQty -= uses
        })
      }

      const presaleDiscounts = availableDiscounts?.filter((discount) => discount.category === 'PRESALE') || []
      const normalPromoItem = findPromoItem('NORMAL')
      const presalePromoItem = findPromoItem('PRESALE')

      if (normalPromoItem) {
        presaleDiscounts.forEach((discount) => {
          if (remainingQty > 0) {
            const uses = Math.min(discount.remainingUses ?? 0, remainingQty)
            const normalEntries = uses * normalPromoItem.entriesPerTicket * normalPromoItem.multiplier

            updatedTicketEntriesTable.normal.entries += normalEntries
            updatedTicketEntriesTable.normal.appliedTickets += uses

            remainingQty -= uses
          }
        })
      }

      if (presalePromoItem && remainingQty > 0) {
        const presaleEntries = remainingQty * presalePromoItem.entriesPerTicket * presalePromoItem.multiplier

        updatedTicketEntriesTable.presale.entries += presaleEntries
        updatedTicketEntriesTable.presale.appliedTickets += remainingQty

        remainingQty = 0
      }

      updateTicketEntriesTable(updatedTicketEntriesTable)

      const totalEntries = Object.values(updatedTicketEntriesTable).reduce((sum, category) => sum + category.entries, 0)

      return totalEntries
    },
    [giveawayPromo, availableDiscounts],
  )

  const getFinalEntries = useCallback(
    (quantity: number, reservation?: ReservationObject) => {
      let remainingQty = quantity

      const updatedTicketEntriesTable: TicketEntriesTable = {
        guild: { entries: 0, appliedTickets: 0 },
        normal: { entries: 0, appliedTickets: 0 },
        presale: { entries: 0, appliedTickets: 0 },
      }

      const tickets = reservation?.ticketOrder?.lines?.tickets ?? []
      const validTickets = tickets.filter((ticket): ticket is ReservationTicket => ticket !== null)

      const findPromoItem = (ticketType: string) => {
        return giveawayPromo?.items?.find((item) => item.ticketType === ticketType) ?? null
      }

      const guildDiscounts = availableDiscounts?.filter((discount) => discount.category === 'GUILD') || []
      const isGuildEligible = guildDiscounts.length > 1

      validTickets.forEach((ticket) => {
        if (ticket.discounts && ticket.discounts.length > 0) {
          ticket.discounts.forEach((discount) => {
            const matchingAvailableDiscount = availableDiscounts?.find((available) => available.code === discount.code)

            if (matchingAvailableDiscount) {
              const category = matchingAvailableDiscount.category

              const promoItem = category === 'GUILD' ? findPromoItem('GUILD') : findPromoItem('NORMAL')
              if (promoItem) {
                const uses = Math.min(matchingAvailableDiscount.remainingUses ?? 0, remainingQty)

                if (promoItem.ticketType === 'GUILD') {
                  if (isGuildEligible) {
                    const guildEntries = Math.min(uses * promoItem.entriesPerTicket * promoItem.multiplier, 100)

                    updatedTicketEntriesTable.guild.entries += guildEntries
                    updatedTicketEntriesTable.guild.entries = Math.min(updatedTicketEntriesTable.guild.entries, 100) // Cap at 100 for GUILD
                    updatedTicketEntriesTable.guild.appliedTickets += uses
                  }
                } else {
                  const normalEntries = uses * promoItem.entriesPerTicket * promoItem.multiplier

                  updatedTicketEntriesTable.normal.entries += normalEntries
                  updatedTicketEntriesTable.normal.appliedTickets += uses
                }
                remainingQty -= uses
              }
            }
          })
        } else {
          const presalePromoItem = findPromoItem('PRESALE')
          if (presalePromoItem) {
            const presaleEntries = presalePromoItem.entriesPerTicket * presalePromoItem.multiplier

            updatedTicketEntriesTable.presale.entries += presaleEntries
            updatedTicketEntriesTable.presale.appliedTickets += 1

            remainingQty -= 1
          }
        }
      })

      updateTicketEntriesTable(updatedTicketEntriesTable)

      const totalEntries = Object.values(updatedTicketEntriesTable).reduce((sum, category) => sum + category.entries, 0)

      return totalEntries
    },
    [giveawayPromo, availableDiscounts],
  )

  const detectLanguageDifference = useCallback(
    (showTime: TheatricalShowtime) => {
      const showTimeLanguage = showTime?.language
      const subtitleLanguage = showTime?.subtitleLanguage
      const wrongLanguage = showTimeLanguage !== locale && showTimeLanguage != null
      const wrongSubtitle = subtitleLanguage !== locale && subtitleLanguage != null

      return wrongLanguage || wrongSubtitle
    },
    [locale],
  )

  const getLanguageWarningMessage = useCallback(
    (showTimeLanguage: string, subtitleLanguage: string) => {
      const wrongLanguage = showTimeLanguage !== locale && showTimeLanguage != null
      const wrongSubtitle = subtitleLanguage !== locale && subtitleLanguage != null

      if (wrongLanguage && wrongSubtitle) {
        return t(
          'showtimeLanguageAndSubtitlesWarning',
          'The showtime you have selected is in {{language}} with {{subtitleLanguage}} subtitles',
          { language: getLanguageName(showTimeLanguage), subtitleLanguage: getLanguageName(subtitleLanguage) },
        )
      }

      if (wrongLanguage) {
        return t('showtimeLanguageWarning', 'The showtime you have selected is in {{language}}.', {
          language: getLanguageName(showTimeLanguage),
        })
      }

      if (wrongSubtitle) {
        return t('showtimeSubtitleWarning', 'The showtime you have selected has {{subtitleLanguage}} subtitles.', {
          subtitleLanguage: getLanguageName(subtitleLanguage),
        })
      }
    },
    [locale, t],
  )

  const handleLanguageWarning = useCallback(
    (
      setLocaleWarningModalState: React.Dispatch<React.SetStateAction<LocaleWarningModalState>>,
      showTime: TheatricalShowtime,
      callback: () => void,
    ) => {
      const languageWarningMessage = getLanguageWarningMessage(
        showTime?.language as string,
        showTime?.subtitleLanguage as string,
      )
      if (languageWarningMessage) {
        return setLocaleWarningModalState({
          isOpen: true,
          callback: () => {
            callback()
          },
          warning: languageWarningMessage,
        })
      }
    },
    [getLanguageWarningMessage],
  )

  const discount = useMemo(() => {
    const isPromoValid = isTheatricalPromoValid(promo)
    if (!isPromoValid) return

    const pendingDiscountName = promoExperiment?.pendingReservationDiscountName ?? promo?.pendingReservationDiscountName

    const pendingDiscount = reservation?.pendingDiscounts?.find((discount) => {
      return discount?.name?.toLowerCase() === pendingDiscountName?.toLowerCase()
    })

    if (pendingDiscount) {
      const percent = Math.floor(pendingDiscount?.value * 100)
      return {
        description: t('discountPercent', '{{percent}}%', { percent }),
        value: percent,
        name: pendingDiscount?.name,
        code: promo?.code as string,
      }
    }

    if (!pendingDiscount && promo?.discount) {
      const percent = Math.floor(promo?.discount * 100)
      return {
        description: t('discountPercent', '{{percent}}%', { percent }),
        value: percent,
        code: promo?.code as string,
      }
    }
  }, [promo, promoExperiment, reservation?.pendingDiscounts, t])

  const adjustedDiscount = useMemo(() => {
    if (promo?.type === TicketPromoTypes.EarlyBird) {
      const startDate = new Date(promo?.startDate)
      const currentDate = new Date()
      const daysSinceStart = Math.floor((currentDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24))
      const newDiscount = Math.max(15, Number(discount?.value) - daysSinceStart * 1)
      return isNaN(newDiscount) ? discount?.value : newDiscount
    }
    return discount?.value
  }, [promo, discount?.value])

  const trackTicketIntent = useCallback(
    (analyticsPayload: TicketsAnalyticsPayload) => {
      publishTicketIntent(analyticsPayload)
      track('Tickets Checkout Intent', analyticsPayload)
    },
    [track],
  )

  useEffect(() => {
    const shouldShowTicketUpdateShowtimeError = getBooleanFromLocalStorage('TICKET_SHOWTIME_SET_ERROR')
    if (shouldShowTicketUpdateShowtimeError) {
      showToast(t('couldntSelectShowtimeSelectAnother', 'We are unable to select that showtime, please try another!'))
      writeToLocalStorage('TICKET_SHOWTIME_SET_ERROR', false)
    }
  }, [showToast, t])

  const value = useMemo(() => {
    return {
      query,
      isDatesDisabled,
      isFreeTicketPromo,
      routerIsReady,
      releaseDate,
      handleMobileShare,
      openSharingModal,
      closeSharingModal,
      theatricalRelease,
      guildDiscountCodes,
      guildTicketsCount,
      discount,
      detectLanguageDifference,
      trackTicketIntent,
      shouldHideDiscountsForMarketing,
      handleLanguageWarning,
      setPromoExperiment,
      promoExperiment,
      promo,
      adjustedDiscount,
      getEstimatedEntries,
      getFinalEntries,
      ticketEntriesTable,
    }
  }, [
    query,
    isDatesDisabled,
    isFreeTicketPromo,
    routerIsReady,
    releaseDate,
    handleMobileShare,
    openSharingModal,
    closeSharingModal,
    theatricalRelease,
    guildDiscountCodes,
    guildTicketsCount,
    discount,
    detectLanguageDifference,
    trackTicketIntent,
    shouldHideDiscountsForMarketing,
    handleLanguageWarning,
    promoExperiment,
    promo,
    adjustedDiscount,
    getEstimatedEntries,
    getFinalEntries,
    ticketEntriesTable,
  ])

  return (
    <TicketsContext.Provider value={value}>
      {children}
      <SharingModal
        className="z-[80]"
        open={displaySharingModal}
        onClose={closeSharingModal}
        url={`${paths.base}${currentPathWithoutQueryParams}`}
      />
    </TicketsContext.Provider>
  )
}

export const useTicketsContext = (): TicketsContextValues => {
  return React.useContext(TicketsContext) as TicketsContextValues
}
